// Include media for media queries
@import "/node_modules/include-media/dist/include-media";

$font-superStar: "SuperStar", Verdana, Geneva, Tahoma, sans-serif;
$cursor-Pointer: url("../../assets/media/Cursors/cursorPointer.png"), pointer;
$pixelBorderRadius: polygon(
  0px 8px,
  2px 8px,
  2px 4px,
  4px 4px,
  4px 2px,
  6px 2px,
  8px 2px,
  8px 0px,
  calc(100% - 8px) 0px,
  calc(100% - 8px) 2px,
  calc(100% - 4px) 2px,
  calc(100% - 4px) 4px,
  calc(100% - 2px) 4px,
  calc(100% - 2px) 6px,
  calc(100% - 2px) 8px,
  100% 8px,
  100% calc(100% - 8px),
  calc(100% - 2px) calc(100% - 8px),
  calc(100% - 2px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 2px),
  calc(100% - 6px) calc(100% - 2px),
  calc(100% - 8px) calc(100% - 2px),
  calc(100% - 8px) 100%,
  8px 100%,
  8px calc(100% - 2px),
  4px calc(100% - 2px),
  4px calc(100% - 4px),
  2px calc(100% - 4px),
  2px calc(100% - 6px),
  2px calc(100% - 8px),
  0px calc(100% - 8px)
);

// ! FAQ modal
.faqmodal-back {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.52);
  z-index: 5;
}

.faqmodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 6;
  // width: 35rem;
  // height: 45rem;
  opacity: 0;
  width: 0;
  height: 45rem;
  background-color: #202020;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.25rem 1rem;
  border-radius: 20px;
  border: 12.5px solid transparent;
  border-image: url("../../assets/media/icons/LoginBorder.png") 25 stretch;
  @include media("<=480px") {
    width: 100vw;
    border: none;
    height: 32.5rem;
    overflow-y: auto;
    text-align: center;
    padding: 1rem 7rem 6rem 7rem;
  }
  .faqmodal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 36px;
    color: rgb(151, 151, 151);
    font-family: $font-superStar;
    opacity: 0;
    @include media("<=480px") {
      line-height: normal;
      justify-content: center;
      font-size: 28px;
    }
    .faqmodal-header-close {
      cursor: $cursor-Pointer;
      color: white;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      clip-path: $pixelBorderRadius;
      @include media("<=480px") {
        width: 40px;
        height: 40px;
        position: relative;
        left: 1.25rem;
      }
      &:hover {
        background-color: #646464;
      }
      svg {
        path {
          fill: rgb(151, 151, 151);
        }
      }
    }
  }
  .faqmodal-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    line-height: normal;
    font-size: 22px;
    height: 43rem;
    .question {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .faqmodal-content-title {
        font-family: $font-superStar;
        color: rgb(151, 151, 151);
      }
      p {
        font-size: 18px;
        text-indent: 20px;
        @include media("<=480px") {
          text-align: justify;
          padding-bottom: 1rem;
        }
      }
    }
  }
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0rem 3.75rem 0 2.5rem;
  position: relative;
  margin-bottom: 2.75rem;
  z-index: 1;
  @include media("<=889px") {
    padding: 0 0.5rem;
    height: 5rem;
  }
  .header-left-side {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    color: #ffa556;
    font-size: 20px;
    cursor: $cursor-Pointer;
    @include media("<=889px") {
      scale: 1.5;
    }
    @include media("<=480px") {
      scale: 1;
    }
    &:hover {
      text-decoration: underline;
    }
    .trading-rules {
      position: relative;
      top: 0.125rem;
      @include media("<=889px") {
        display: none;
      }
    }
    @include media("<=889px") {
      font-size: 20px;
      position: relative;
      gap: 0.75rem;
      transform: translateX(5px);
    }
  }
  .header-middle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    display: flex;
    align-items: center;
    gap: 2rem;
    font-family: $font-superStar;
    transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    &.logged {
      @include media("<=889px") {
        left: 32.5%;
        transform: translateX(-50%);
      }
      @include media("<=480px") {
        left: 50%;
        transform: translateX(-50%);
      }
    }
    @include media("<=889px") {
      gap: 0.25rem;
    }
    @include media("<=480px") {
      left: 50%;
      transform: translateX(-50%);
    }
    a {
      cursor: $cursor-Pointer;
      color: #dedede;
      transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      @include media("<=889px") {
        font-size: 22px;
      }
      @include media("<=480px") {
        font-size: 18px;
      }
      &:hover {
        color: #ffffff;
      }
      &.active {
        color: #ffa556;
        border-bottom: #ffa556 4px solid;
      }
    }
    .leaderboard-button {
      font-size: 24px;
      color: #dedede;
      transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      position: relative;
      @include media("<=889px") {
        font-size: 22px;
      }
      @include media("<=480px") {
        font-size: 18px;
      }
      &:hover {
        color: #ffffff;
        .leaderboard-pop-wrapper {
          opacity: 1;
          transform: translateY(0) translateX(-50%);
          pointer-events: all;
        }
      }
      &.active {
        color: #ffa556;
        border-bottom: #ffa556 4px solid;
      }

      .leaderboard-pop-wrapper {
        display: flex;
        flex-direction: column;
        position: absolute;
        padding: 0.5rem;
        left: 50%;
        background: rgba(255, 255, 255, 0.2);
        clip-path: $pixelBorderRadius;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        opacity: 0;
        transform: translateY(-10px) translateX(-50%);
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        pointer-events: none;
        button {
          background-color: #202020;
          color: white;
          font-size: 28px;
          padding: 0.5rem 1rem;
          clip-path: $pixelBorderRadius;
          cursor: $cursor-Pointer;
          transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
          &:hover {
            background-color: #101010;
          }
        }
      }
    }
  }
  .header-right-side {
    display: flex;
    gap: 1rem;
    align-items: center;
    @include media("<=480px") {
      gap: 0.5rem;
      position: absolute;
      bottom: -2.5rem;
      left: 50%;
      transform: translateX(-50%);
    }
    .info-box-balance-wrapper {
      font-family: $font-superStar;
      color: white;
      font-size: 20px;
      padding: 0.5rem 1rem;
      clip-path: $pixelBorderRadius;
      background-color: #202020;
      display: flex;
      align-items: center;
      gap: 0.2rem;
      @include media("<=480px") {
        padding: 0.5rem 0;
        width: 12rem;
        flex-direction: column;
      }
      img {
        width: 17px;
        height: 28px;
        @include media("<=480px") {
          display: none;
        }
      }
    }
  }
}
