// Include media for media queries
@import "/node_modules/include-media/dist/include-media";

$font-superStar: "SuperStar", Verdana, Geneva, Tahoma, sans-serif;
$font-Pixellari: "Pixellari", Verdana, Geneva, Tahoma, sans-serif;
$cursor-Pointer: url("../../assets/media/Cursors/cursorPointer.png"), pointer;

.top-canditate-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem 0.5rem 0;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  border-bottom: 1px solid #1b1b1a;
  @include media("<=480px") {
  }
  &:hover {
    background-color: #353535;
  }

  .top-candidate-left-side {
    display: flex;
    align-items: center;
    gap: 3rem;
    @include media("<=480px") {
      gap: 1.5rem;
    }
    .top-rank {
      width: 5rem;
      border-radius: 0 4px 4px 0;
      font-size: 28px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 0.6rem 0 0;
      gap: 0.25rem;
      font-weight: 1000;
      color: rgba(214, 214, 214, 1);
      @include media("<=480px") {
        width: 4rem;
      }
      &.one {
        .top-rank-number {
          margin-left: 0.55rem;
        }
        color: #fff;
        font-size: 36px;
        background-color: #cbb765;
        @include media("<=480px") {
          font-size: 28px;
        }
      }
      &.two {
        color: #fff;
        font-size: 36px;
        background-color: #8e91a7;
        @include media("<=480px") {
          font-size: 28px;
        }
      }
      &.three {
        color: #fff;
        font-size: 36px;
        background-color: #a86243;
        @include media("<=480px") {
          font-size: 28px;
        }
      }
      img {
        width: 25px;
        height: 25px;
        @include media("<=480px") {
          width: 15px;
          height: 15px;
        }
      }
      .top-wallet-address {
        font-size: 18px;
      }
    }
  }
  .top-canditate-right-side {
    font-size: 28px;
    position: relative;
    @include media("<=480px") {
      font-size: 22px;
      width: 50%;
      text-align: right;
    }
    span {
      color: white;

      @include media("<=480px") {
        font-size: 18px;
        position: absolute;
        top: -1rem;
        right: 0;
      }
      &.red {
        color: #ef4444;
      }
      &.green {
        color: #4cf752;
      }
    }
  }
}

.no-result {
  padding-top: 1rem;
  padding-left: 2rem;
  font-size: 36px;
  color: #ddd;
  font-family: $font-Pixellari;
}
