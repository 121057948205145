// Include media for media queries
@import "/node_modules/include-media/dist/include-media";

$font-superStar: "SuperStar", Verdana, Geneva, Tahoma, sans-serif;
$cursor-Pointer: url("../../assets/media/Cursors/cursorPointer.png"), pointer;
$pixelBorderRadius: polygon(
  0px 8px,
  2px 8px,
  2px 4px,
  4px 4px,
  4px 2px,
  6px 2px,
  8px 2px,
  8px 0px,
  calc(100% - 8px) 0px,
  calc(100% - 8px) 2px,
  calc(100% - 4px) 2px,
  calc(100% - 4px) 4px,
  calc(100% - 2px) 4px,
  calc(100% - 2px) 6px,
  calc(100% - 2px) 8px,
  100% 8px,
  100% calc(100% - 8px),
  calc(100% - 2px) calc(100% - 8px),
  calc(100% - 2px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 2px),
  calc(100% - 6px) calc(100% - 2px),
  calc(100% - 8px) calc(100% - 2px),
  calc(100% - 8px) 100%,
  8px 100%,
  8px calc(100% - 2px),
  4px calc(100% - 2px),
  4px calc(100% - 4px),
  2px calc(100% - 4px),
  2px calc(100% - 6px),
  2px calc(100% - 8px),
  0px calc(100% - 8px)
);

.login-button {
  text-align: center;
  position: relative;
  font-family: $font-superStar;
  font-size: 22px;
  cursor: $cursor-Pointer;
  color: #f27921;
  clip-path: $pixelBorderRadius;
  padding: 0.4rem 1rem;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  background-color: #202020;
  @include media("<=480px") {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover .user {
    opacity: 0;
    user-select: none;
    pointer-events: none;
  }
  &:hover .logout {
    opacity: 1;
    user-select: auto;
    pointer-events: auto;
  }
  .user {
    transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .logout {
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    user-select: none;
    pointer-events: none;
    color: #f27921;
  }
  &:hover {
    background-color: #151515;
  }
}
