// Include media for media queries
@import "/node_modules/include-media/dist/include-media";

$font-superStar: "SuperStar", Verdana, Geneva, Tahoma, sans-serif;
$cursor-Pointer: url("../../assets/media/Cursors/cursorPointer.png"), pointer;
$pixelBorderRadius: polygon(
  0px 8px,
  2px 8px,
  2px 4px,
  4px 4px,
  4px 2px,
  6px 2px,
  8px 2px,
  8px 0px,
  calc(100% - 8px) 0px,
  calc(100% - 8px) 2px,
  calc(100% - 4px) 2px,
  calc(100% - 4px) 4px,
  calc(100% - 2px) 4px,
  calc(100% - 2px) 6px,
  calc(100% - 2px) 8px,
  100% 8px,
  100% calc(100% - 8px),
  calc(100% - 2px) calc(100% - 8px),
  calc(100% - 2px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 2px),
  calc(100% - 6px) calc(100% - 2px),
  calc(100% - 8px) calc(100% - 2px),
  calc(100% - 8px) 100%,
  8px 100%,
  8px calc(100% - 2px),
  4px calc(100% - 2px),
  4px calc(100% - 4px),
  2px calc(100% - 4px),
  2px calc(100% - 6px),
  2px calc(100% - 8px),
  0px calc(100% - 8px)
);

.home-info-box {
  color: white;
  font-family: $font-superStar;
  font-size: 24px;
  line-height: normal;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  width: 25rem;
  background-color: #252525;
  @include media("<=889px") {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  @include media("<=480px") {
    flex-direction: column;
  }
  .home-info-box-left {
    @include media("<=889px") {
      display: flex;
      align-items: center;
    }
    .portfolio {
      display: flex;
      gap: 0.5rem;
      color: white;
      font-family: $font-superStar;
      font-size: 22px;
      @include media("<=889px") {
        display: none;
      }
      @include media("<=480px") {
        align-items: center;
      }

      .eth {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .eth-count-full {
          position: absolute;
          padding: 0.5rem 1.5rem;
          background-color: #202020a6;
          clip-path: $pixelBorderRadius;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          z-index: 5;
          top: -3rem;
          display: none;
          user-select: none;
          pointer-events: none;
          transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
          &.show {
            display: flex;
          }
        }
        .eth-count {
          padding: 0.5rem 1rem;
          background-color: #202020;
          clip-path: $pixelBorderRadius;
          display: flex;
          align-items: center;
          gap: 0.2rem;
        }
      }
      .usd-count {
        padding: 0.5rem 1rem;
        clip-path: $pixelBorderRadius;
        background-color: #202020;
        display: flex;
        align-items: center;
        gap: 0.2rem;
        img {
          width: 15px;
        }
      }
    }
    .round-time-wrapper {
      word-spacing: -1px;
    }
    .info-box-balance-wrapper {
      display: flex;
      flex-direction: row;
      gap: 0.25rem;

      img {
        width: 17px;
        height: 28px;
      }
    }
  }
  .portfolio {
    display: flex;
    gap: 0.5rem;
    color: white;
    font-family: $font-superStar;
    font-size: 22px;
    align-items: center;
    @include media(">889px") {
      display: none;
    }
    .balance {
      color: rgba(222, 222, 222, 0.46);
    }
    .eth-count {
      padding: 0.5rem 1rem;
      background-color: #202020;
      clip-path: $pixelBorderRadius;
      display: flex;
      align-items: center;
      gap: 0.2rem;
      @include media("<=480px") {
        padding: 0.25rem 0.5rem;
      }
    }
    .usd-count {
      padding: 0.5rem 1rem;
      clip-path: $pixelBorderRadius;
      background-color: #202020;
      display: flex;
      align-items: center;
      gap: 0.2rem;
      @include media("<=480px") {
        padding: 0.25rem 0.5rem;
      }
      img {
        width: 15px;
      }
    }
  }
}
