// Include media for media queries
@import "/node_modules/include-media/dist/include-media";

$font-superStar: "SuperStar", Verdana, Geneva, Tahoma, sans-serif;
$font-Pixellari: "Pixellari", Verdana, Geneva, Tahoma, sans-serif;
$cursor-Pointer: url("../../assets/media/Cursors/cursorPointer.png"), pointer;

.weekly-canditate-wrapper {
  cursor: $cursor-Pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem 0.5rem 0;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  border-bottom: 1px solid #1b1b1a;
  position: relative;
  &.winner {
    -webkit-box-shadow: inset 0px 0px 50px -12px rgb(2, 163, 5);
    -moz-box-shadow: inset 0px 0px 50px -12px rgb(2, 163, 5);
    box-shadow: inset 0px 0px 50px -12px rgb(2, 163, 5);
  }

  &:hover {
    background-color: #353535;
  }

  .weekly-candidate-left-side {
    display: flex;
    align-items: center;
    gap: 3rem;
    @include media("<=480px") {
      gap: 0.5rem;
    }
    .weekly-rank {
      width: 5rem;
      border-radius: 0 4px 4px 0;
      font-size: 28px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 0 1rem 0 0;
      gap: 0.25rem;
      font-weight: 1000;
      color: rgba(214, 214, 214, 1);
      svg {
        position: relative;
        left: 0.5rem;
        width: 20px;
        height: 20px;
      }
      .weekly-wallet-address {
        font-size: 18px;
      }
    }
  }
  .weekly-candidate-right-side {
    position: absolute;
    right: 1rem;
    font-size: 28px;
    display: flex;
    width: 41%;
    align-items: center;
    justify-content: space-between;
    @include media("<=889px") {
      width: 50%;
    }
    .startend-wrapper {
      display: flex;
      justify-content: space-between;
      position: relative;
      left: 1rem;
      width: 56.5%;
      @include media(">=1920px") {
        width: 54.5%;
      }
      @include media("<=480px") {
        display: none;
      }
    }
    .weekly-candidate-lostgainedamount {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      @include media("<=480px") {
      }
      .rate-lost-raise {
        position: absolute;
        top: -0.65rem;
        right: 0;
        font-size: 18px;
        color: #fff;
        &.red {
          color: #ef4444;
        }
        &.green {
          color: #4fc94f;
        }
        &.white {
          color: #fff;
        }
      }
    }
  }
}

.no-data {
  padding-top: 1rem;
  padding-left: 2rem;
  font-size: 36px;
  color: #ddd;
  font-family: $font-Pixellari;
  @include media("<=480px") {
    font-size: 24px;
    padding: 1rem;
  }
}
