// Include media for media queries
@import "/node_modules/include-media/dist/include-media";

$font-superStar: "SuperStar", Verdana, Geneva, Tahoma, sans-serif;
$cursor-Pointer: url("../../assets/media/Cursors/cursorPointer.png"), pointer;
$pixelBorderRadius: polygon(
  0px 8px,
  2px 8px,
  2px 4px,
  4px 4px,
  4px 2px,
  6px 2px,
  8px 2px,
  8px 0px,
  calc(100% - 8px) 0px,
  calc(100% - 8px) 2px,
  calc(100% - 4px) 2px,
  calc(100% - 4px) 4px,
  calc(100% - 2px) 4px,
  calc(100% - 2px) 6px,
  calc(100% - 2px) 8px,
  100% 8px,
  100% calc(100% - 8px),
  calc(100% - 2px) calc(100% - 8px),
  calc(100% - 2px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 2px),
  calc(100% - 6px) calc(100% - 2px),
  calc(100% - 8px) calc(100% - 2px),
  calc(100% - 8px) 100%,
  8px 100%,
  8px calc(100% - 2px),
  4px calc(100% - 2px),
  4px calc(100% - 4px),
  2px calc(100% - 4px),
  2px calc(100% - 6px),
  2px calc(100% - 8px),
  0px calc(100% - 8px)
);

.mobile-side-menu {
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(58, 58, 58, 0.9);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  transform: translateX(100%);
  transition: 0.5s cubic-bezier(0.25, 0.13, 0.31, 0.85);
  &.active {
    transform: translateX(0);
    display: flex;
    transition: 0.5s cubic-bezier(0.25, 0.13, 0.31, 0.85);
  }
  @include media("<=889px") {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  a {
    font-size: 36px;
  }
  .close-btn-wrapper {
    position: absolute;
    top: 1rem;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 50px;
    align-items: center;
    padding: 2rem;
    .close-btn {
      width: 55px;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      clip-path: $pixelBorderRadius;
      background-color: #8a8a8a;
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.navbar {
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100vw;
  background-color: #0c0f12;
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  .navbar-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 3rem;
    border-bottom: 2px solid #3e3e3e;
    box-shadow: 0px 3.5px 2.2px rgba(0, 0, 0, 0.07),
      0px 8.4px 5.3px rgba(0, 0, 0, 0.101), 0px 15.8px 10px rgba(0, 0, 0, 0.125),
      0px 28.1px 17.9px rgba(0, 0, 0, 0.149),
      0px 52.6px 33.4px rgba(0, 0, 0, 0.18), 0px 126px 80px rgba(0, 0, 0, 0.25);
    @include media("<=480px") {
      padding: 0.75rem 2.5rem;
    }
    .nav-left-side {
      display: flex;
      align-items: center;
      gap: 1.25rem;
      img {
        cursor: $cursor-Pointer;
        width: 100px;
        @include media("<=480px") {
          transform: translateX(-25px);
          width: 85px;
        }
      }
      a {
        cursor: $cursor-Pointer;
        text-decoration: none;
        color: #dedede;
        font-family: $font-superStar;
        font-size: 24px;
        transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
        @include media("<=889px") {
          display: none;
        }
        &:hover {
          color: #fff;
        }
        &.active {
          color: #ffa556;
        }
      }
    }
    .nav-right-side {
      display: flex;
      margin-right: 1rem;
      gap: 1.25rem;
      @include media("<=480px") {
        margin: 0;
        gap: 0.5rem;
        transform: translateX(25px);
      }
      .mobile-side {
        display: none;
        width: 50px;
        height: 50px;
        background-color: #202020;
        clip-path: $pixelBorderRadius;
        transform: translateX(25px);
        @include media("<=889px") {
          display: flex;
          align-items: center;
          justify-content: center;
          transform: translateX(0);
        }
        svg {
          width: 32.5px;
          height: 32.5px;
        }
      }

      .bearcoin-container {
        display: flex;
        align-items: center;
        //   background-color: red;
        width: 7.5rem;
        border: 2px solid #3e3e3e;
        border-radius: 7.5px;
        .bear-coin-wrapper {
          padding: 0.25rem;
          border-right: 2px solid #3e3e3e;
          width: 35%;
        }
        .bearcoin-value-wrapper {
          display: flex;
          width: 65%;
          height: 100%;
          align-items: center;
          justify-content: center;
          .bear-coin {
            color: #ffffff80;
            font-family: $font-superStar;
            text-align: center;
            font-size: 22px;
          }
        }
      }
      .wallet-container {
        display: flex;
        align-items: center;
        width: 9rem;
        border: 2px solid #3e3e3e;
        border-radius: 7.5px;
        .wallet-icon-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30%;
          border-right: 2px solid #3e3e3e;
          // padding: 0.25rem;
          height: 100%;
        }
        .bearcoin-value-wrapper {
          width: 70%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          padding: 0.25rem;
          .wallet-value {
            color: #ffffff80;
            font-family: $font-superStar;
            text-align: center;
            font-size: 22px;
          }
          img {
            width: 15px;
          }
        }
      }
    }
  }
  .navbar-bottom {
    width: 100vw;
    height: 25rem;
    background-image: url("../../assets/media/images/NavBanner.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    @include media("<=480px") {
      height: 17.5rem;
    }
    .allowlist-game {
      font-family: $font-superStar;
      color: #ffb137;
      font-size: 18px;
      @include media("<=889px") {
        font-size: 24px;
      }
    }
    .eth-simulator {
      font-family: $font-superStar;
      font-style: normal;
      font-weight: 400;
      font-size: 82px;
      line-height: 66px;
      color: #ffffff;
      text-shadow: 0px 8px 0px #000000;
      @include media("<=480px") {
        font-size: 46px;
        text-align: center;
        text-shadow: 0px 4px 0px #000000;
      }
    }
    .navbar-bottom-gradient {
      position: absolute;
      background: rgb(38, 20, 18);
      background: linear-gradient(
        0deg,
        rgba(38, 20, 18, 1) 0%,
        rgba(38, 20, 18, 0.75) 50%,
        rgba(0, 0, 0, 0) 100%
      );
      bottom: -1px;
      width: 100%;
      height: 10rem;
    }
  }
}
