// Include media for media queries
@import "/node_modules/include-media/dist/include-media";

$font-superStar: "SuperStar", Verdana, Geneva, Tahoma, sans-serif;
$font-Pixellari: "Pixellari", Verdana, Geneva, Tahoma, sans-serif;
$cursor-Pointer: url("../../assets/media/Cursors/cursorPointer.png"), pointer;
$pixelBorderRadius: polygon(
  0px 8px,
  2px 8px,
  2px 4px,
  4px 4px,
  4px 2px,
  6px 2px,
  8px 2px,
  8px 0px,
  calc(100% - 8px) 0px,
  calc(100% - 8px) 2px,
  calc(100% - 4px) 2px,
  calc(100% - 4px) 4px,
  calc(100% - 2px) 4px,
  calc(100% - 2px) 6px,
  calc(100% - 2px) 8px,
  100% 8px,
  100% calc(100% - 8px),
  calc(100% - 2px) calc(100% - 8px),
  calc(100% - 2px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 2px),
  calc(100% - 6px) calc(100% - 2px),
  calc(100% - 8px) calc(100% - 2px),
  calc(100% - 8px) 100%,
  8px 100%,
  8px calc(100% - 2px),
  4px calc(100% - 2px),
  4px calc(100% - 4px),
  2px calc(100% - 4px),
  2px calc(100% - 6px),
  2px calc(100% - 8px),
  0px calc(100% - 8px)
);

.myprofile {
  color: white;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: hsla(5, 36%, 11%, 1);
  .myprofile-top {
    display: flex;
    padding: 0 2rem;
    margin-bottom: 5rem;
    @include media("<=480px") {
      padding: 0;
      margin-top: 2.5rem;
      margin-bottom: 0;
    }
    .myprofile-top-left {
      width: 50%;
      height: 34rem;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-end;
      .edit-profile {
        display: flex;
        flex-direction: column;
        .profile-title-wrapper {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          font-family: $font-superStar;
          font-size: 24px;
          margin-left: 10px;
          .user-icon {
            width: 25px;
            height: 25px;
          }
        }
        .profile-wrapper {
          display: flex;
          gap: 1rem;
          .profile-image {
            display: flex;
            label {
              display: flex;
              align-items: center;
              justify-content: center;
              &:hover .change-photo {
                opacity: 1;
              }
              .change-photo {
                background-color: rgba(0, 0, 0, 0.693);
                position: absolute;
                width: 175px;
                height: 175px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: $font-superStar;
                font-size: 24px;
                pointer-events: none;
                user-select: none;
                opacity: 0;
                transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
              }
              .avatar {
                cursor: $cursor-Pointer;
                user-select: none;
                width: 175px;
                height: 175px;
                border-radius: 5px;
                background-color: white;
                margin: 10px;
                object-fit: cover;
                box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
                  6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
                  12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
                  22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
                  41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
                  100px 100px 80px rgba(0, 0, 0, 0.07);
              }
            }
            #file {
              display: none;
            }
          }

          .username-wrapper-noedit {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-family: $font-superStar;
            .username-label {
              font-size: 18px;
              line-height: normal;
              margin-left: 5px;
            }
            .username-input {
              display: flex;
              align-items: center;
              gap: 0.5rem;
              .username-container {
                display: flex;
                align-items: center;
                width: 17.28rem;
                background-color: #35353694;
                border: none;
                color: white;
                font-family: $font-superStar;
                font-size: 20px;
                line-height: normal;
                padding: 10px 10px;
                border-radius: 6px;
                clip-path: $pixelBorderRadius;
                &:focus {
                  outline: none;
                }
              }
              .username-edit-button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50px;
                height: 50px;
                transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
                clip-path: $pixelBorderRadius;
                &:hover {
                  cursor: $cursor-Pointer;
                  background-color: #353536;
                }
                svg {
                  width: 25px;
                  height: 25px;
                  path {
                    fill: white;
                  }
                }
              }
            }
          }

          .username-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .username-label {
              font-family: $font-superStar;
              font-size: 18px;
              line-height: normal;
              margin-left: 5px;
            }
            .username-input {
              display: flex;
              align-items: center;
              input {
                clip-path: $pixelBorderRadius;
                width: 12.5rem;
                background-color: #353536;
                border: none;
                color: white;
                font-family: $font-superStar;
                font-size: 20px;
                line-height: normal;
                padding: 10px 10px;
                border-radius: 6px;
                &:focus {
                  outline: none;
                }
              }
              button {
                clip-path: $pixelBorderRadius;
                cursor: $cursor-Pointer;
                font-size: 20px;
                font-family: $font-superStar;
                color: white;
                border-radius: 6px;
                padding: 5px 10px;
                border: none;
                outline: none;
                background-color: #353536;
                transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
                &:hover {
                  background-color: #2d2d2d;
                }
              }
              .close-username-edit {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                clip-path: $pixelBorderRadius;
                transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
                &:hover {
                  cursor: $cursor-Pointer;
                  background-color: #353536;
                }
                &:hover svg {
                  opacity: 1;
                }
                svg {
                  opacity: 0.5;
                  width: 17.5px;
                  height: 17.5px;
                  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
                  path {
                    fill: white;
                  }
                }
              }
            }
          }
        }
      }
    }

    // ! My Profile Top Right
    .myprofile-top-right {
      width: 100%;
      padding: 0 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      @include media("<=480px") {
        padding: 0;
      }
      .profile-holdings-wrapper {
        user-select: none;
        gap: 0.7rem;
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: #0b0b0b;
        width: 35rem;
        height: 32.5rem;
        border-radius: 4px;
        box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
          6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
          12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
          22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
          41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
          100px 100px 80px rgba(0, 0, 0, 0.07);
        @include media("<=480px") {
          width: 100%;
          border-radius: 0;
        }
        .profile-holdings-title {
          user-select: none;
          text-align: left;
          width: 95%;
          font-family: $font-superStar;
          font-size: 24px;
        }
        .eth-box {
          padding: 0 0.5rem;
          display: flex;
          align-items: center;
          background-color: #222222;
          width: 95%;
          justify-content: space-between;
          border-radius: 3px;
          .eth-value {
            font-family: $font-superStar;
            font-size: 36px;
            color: white;
            margin-left: 10px;
          }
          .eth-icon {
            color: #dedede;
            display: flex;
            gap: 0.5rem;
            font-family: $font-superStar;
            font-size: 24px;
            align-items: center;
            img {
              width: 19px;
              height: 26px;
            }
          }
        }
        .eth-holding-box {
          padding: 0 0.5rem;
          display: flex;
          align-items: center;
          background-color: #222222;
          width: 95%;
          justify-content: space-between;
          border-radius: 3px;
          .eth-holding-value {
            font-family: $font-superStar;
            font-size: 36px;
            color: white;
            margin-left: 10px;
          }
          .eth-holding-icon {
            color: #dedede;
            display: flex;
            gap: 0.5rem;
            font-family: $font-superStar;
            font-size: 24px;
            align-items: center;
            img {
              width: 19px;
              height: 26px;
            }
          }
        }
        .usd-box {
          padding: 0 0.5rem;
          display: flex;
          align-items: center;
          background-color: #222222;
          width: 95%;
          justify-content: space-between;
          border-radius: 3px;
          .usd-value {
            font-family: $font-superStar;
            font-size: 36px;
            color: white;
            margin-left: 10px;
          }
          .usd-icon {
            color: #dedede;
            display: flex;
            gap: 0.5rem;
            font-family: $font-superStar;
            font-size: 24px;
            align-items: center;
            img {
              width: 19px;
              height: 26px;
            }
          }
        }
        .usd-holding-box {
          padding: 0 0.5rem;
          display: flex;
          align-items: center;
          background-color: #222222;
          width: 95%;
          justify-content: space-between;
          border-radius: 3px;
          .usd-holding-value {
            font-family: $font-superStar;
            font-size: 36px;
            color: white;
            margin-left: 10px;
          }
          .usd-holding-icon {
            color: #dedede;
            display: flex;
            gap: 0.5rem;
            font-family: $font-superStar;
            font-size: 24px;
            align-items: center;
            img {
              width: 19px;
              height: 26px;
            }
          }
        }

        .line {
          width: 100%;
          height: 1px;
          background-color: #737373;
          margin: -0.25rem 0;
        }

        .total-balance-box {
          padding: 0 0.5rem;
          display: flex;
          align-items: center;
          background-color: #222222;
          width: 95%;
          justify-content: space-between;
          border-radius: 3px;
          .usd-total-value {
            font-family: $font-superStar;
            font-size: 36px;
            color: white;
            margin-left: 10px;
            width: 40%;
            overflow: hidden;
          }
          .usd-total-icon {
            width: 60%;
            color: #dedede;
            display: flex;
            justify-content: flex-end;
            font-family: $font-superStar;
            font-size: 24px;
            align-items: center;
            line-height: normal;
            gap: 0.5rem;
            img {
              width: 19px;
              height: 26px;
            }
          }
        }
        .reset-button {
          top: 2rem;

          border-radius: 5px;
          width: 10rem;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          position: relative;
          font-family: $font-superStar;
          font-size: 28px;
          cursor: $cursor-Pointer;
          color: #f27921;
          padding: 0.4rem 1rem;
          transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
          background-color: #222222;
          &:hover {
            background-color: #151515;
          }
        }
      }
    }
  }

  // ! RESET WARNING POPUP MODAL
  .reset-warning-modal-back {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
  }
  .reset-warning-popup {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 2rem;
    padding: 0.5rem 1rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30rem;
    opacity: 0;
    background-color: #222222;
    z-index: 101;
    border: 15px solid transparent;
    border-radius: 25px;
    border-image: url("../../assets/media/icons/LoginBorder.png") 30 stretch;
    .reset-warning-popup-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      opacity: 0;
      .areyousure {
        font-family: $font-Pixellari;
        font-size: 32px;
        color: white;
      }
      .reset-close-button {
        position: absolute;
        right: 0;
        top: 0;
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        clip-path: $pixelBorderRadius;
        &:hover {
          cursor: $cursor-Pointer;
          background-color: #646464;
        }
        .reset-closeicon {
          width: 20px;
          height: 20px;
        }
      }
    }
    .resetbuttons-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      opacity: 0;
      .yes-button,
      .no-button {
        width: 47.5%;
        text-align: center;
        position: relative;
        font-family: $font-superStar;
        font-size: 22px;
        cursor: $cursor-Pointer;
        color: #ddd;
        padding: 0.4rem 1rem;
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        border: 1px solid rgba(255, 255, 255, 0.324);
        border-radius: 5px;
        background-color: #191919;
        &:hover {
          background-color: #454545;
          color: #fff;
        }
      }
    }
  }
}
.text-center {
  text-align: center;
}
.margin0auto {
  margin: 0 auto;
}
.lds-dual-ring {
  display: inline-block;
  width: 30px;
  height: 30px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 25px;
  height: 25px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: var(--primary) transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
