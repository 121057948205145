// Include media for media queries
@import "/node_modules/include-media/dist/include-media";

$font-superStar: "SuperStar", Verdana, Geneva, Tahoma, sans-serif;
$font-Pixellari: "Pixellari", Verdana, Geneva, Tahoma, sans-serif;
$cursor-Pointer: url("../../assets/media/Cursors/cursorPointer.png"), pointer;
$pixelBorderRadius: polygon(
  0px 8px,
  2px 8px,
  2px 4px,
  4px 4px,
  4px 2px,
  6px 2px,
  8px 2px,
  8px 0px,
  calc(100% - 8px) 0px,
  calc(100% - 8px) 2px,
  calc(100% - 4px) 2px,
  calc(100% - 4px) 4px,
  calc(100% - 2px) 4px,
  calc(100% - 2px) 6px,
  calc(100% - 2px) 8px,
  100% 8px,
  100% calc(100% - 8px),
  calc(100% - 2px) calc(100% - 8px),
  calc(100% - 2px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 2px),
  calc(100% - 6px) calc(100% - 2px),
  calc(100% - 8px) calc(100% - 2px),
  calc(100% - 8px) 100%,
  8px 100%,
  8px calc(100% - 2px),
  4px calc(100% - 2px),
  4px calc(100% - 4px),
  2px calc(100% - 4px),
  2px calc(100% - 6px),
  2px calc(100% - 8px),
  0px calc(100% - 8px)
);

.leaderboard {
  width: 100vw;
  margin: 0;
  background-color: #261412;
  color: white;
  .search-mobile {
    display: none;
    align-items: center;
    gap: 0.5rem;
    background-color: #363636;
    padding: 0 1rem 0 0.85rem;
    @include media("<=480px") {
      display: flex;
      margin-top: 5rem;
    }
    .search-icon {
      width: 20px;
      height: 20px;
    }
    input {
      background-color: transparent;
      border: none;
      color: white;
      font-family: $font-superStar;
      font-size: 18px;
      outline: none;
      @include media("<=480px") {
        width: 100%;
      }
      &::placeholder {
        color: #9a9a9a;
      }
    }
  }
  .leaderboard-page-top {
    display: flex;
    padding: 0 2.5rem;
    position: relative;
    @include media("<=1500px") {
      flex-direction: column;
      padding: 0 1.5rem;
    }
    @include media("<=889px") {
      padding: 0;
    }
    @include media("<=480px") {
      // margin-top: 5rem;
    }
    .left-side {
      width: 70%;
      transition: 0.7s cubic-bezier(0.075, 0.82, 0.165, 1);
      &.logged {
        width: 99%;
        @include media("<=889px") {
          width: 100%;
        }
      }
      @include media("<=1500px") {
        width: 100%;
      }
      @include media(">=1920px") {
        width: 90%;
      }
      .leaderboard-chart-wrapper {
        clip-path: $pixelBorderRadius;
        overflow: auto;
        overflow-x: hidden;
        height: 55rem;
        background-color: #252525;
        display: flex;
        flex-direction: column;

        @include media("<=1500px") {
          margin-bottom: 1rem;
        }
        @include media("<=889px") {
          width: 100%;
          clip-path: none;
        }
        @include media("<=480px") {
          height: 40rem;
        }
        .leaderboard-chart-top-buttons {
          padding: 0 1rem;
          background-color: #363636;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .leaderboard-chart-top-left {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            div {
              cursor: $cursor-Pointer;
              font-family: $font-superStar;
              font-size: 24px;
              padding: 0.5rem 1rem;
              transition: background-color 0.3s
                cubic-bezier(0.075, 0.82, 0.165, 1);
              @include media("<=480px") {
                font-size: 20px;
              }
              @include media("<=380px") {
                font-size: 18px;
              }
              &.active {
                color: #ffa556;
                border-bottom: 3px solid #ffa556;
                background-color: #444444;
              }
            }
          }

          .leaderboard-chart-top-right {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            background-color: #434343;
            padding: 0 1rem 0 0.85rem;
            border-radius: 18px;
            @include media("<=480px") {
              display: none;
            }
            .search-icon {
              width: 20px;
              height: 20px;
            }
            input {
              background-color: transparent;
              border: none;
              color: white;
              font-family: $font-superStar;
              font-size: 18px;
              outline: none;
              &::placeholder {
                color: #9a9a9a;
              }
            }
          }
        }
        .leaderboard-top-table-headers {
          position: relative;
          display: flex;
          justify-content: space-between;
          padding: 0.25rem 0;
          background-color: #363636;
          border-top: 1px solid #1b1b1a;
          border-bottom: 1px solid #1b1b1a;
          .leaderboard-top-table-header-left {
            display: flex;
            gap: 4rem;
            margin-left: 1rem;
            font-size: 24px;
            font-family: $font-superStar;
            color: #9a9a9a;
            @include media("<=480px") {
              gap: 1.5rem;
            }
          }
          .leaderboard-top-table-header-right {
            width: 41%;
            position: absolute;
            right: 1rem;
            display: flex;
            justify-content: space-between;
            font-size: 24px;
            font-family: $font-superStar;
            color: #9a9a9a;

            @include media("<=889px") {
              width: 48%;
            }
            @include media("<=480px") {
              justify-content: flex-end;
            }
          }
        }
        .leaderboard-list {
          display: flex;
          flex-direction: column;
          font-family: $font-superStar;
          font-size: 24px;
          overflow-x: hidden;
          overflow-y: auto;

          .canditate-wrapper {
            display: flex;
            padding: 0.5rem 1rem 0.5rem 0;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #1b1b1a;
            transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

            &:hover {
              background-color: #353535;
            }

            .canditate-left-side {
              display: flex;
              align-items: center;
              gap: 4rem;
              .rank {
                width: 5rem;
                border-radius: 0 4px 4px 0;
                font-size: 36px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 0 0.6rem 0 0;
                gap: 0.25rem;
                font-weight: 1000;
                &.one {
                  .rank-number {
                    margin-left: 0.55rem;
                  }
                  background-color: #cbb765;
                  box-shadow: 0px 0px 2.2px rgba(0, 0, 0, 0.02),
                    0px 0px 5.3px rgba(0, 0, 0, 0.028),
                    0px 0px 10px rgba(0, 0, 0, 0.035),
                    0px 0px 17.9px rgba(0, 0, 0, 0.042),
                    0px 0px 33.4px rgba(0, 0, 0, 0.05),
                    0px 0px 80px rgba(0, 0, 0, 0.07);
                }
                &.two {
                  background-color: #8e91a7;
                  box-shadow: 0px 0px 2.2px rgba(0, 0, 0, 0.02),
                    0px 0px 5.3px rgba(0, 0, 0, 0.028),
                    0px 0px 10px rgba(0, 0, 0, 0.035),
                    0px 0px 17.9px rgba(0, 0, 0, 0.042),
                    0px 0px 33.4px rgba(0, 0, 0, 0.05),
                    0px 0px 80px rgba(0, 0, 0, 0.07);
                }
                &.three {
                  background-color: #a86243;
                  box-shadow: 0px 0px 2.2px rgba(0, 0, 0, 0.02),
                    0px 0px 5.3px rgba(0, 0, 0, 0.028),
                    0px 0px 10px rgba(0, 0, 0, 0.035),
                    0px 0px 17.9px rgba(0, 0, 0, 0.042),
                    0px 0px 33.4px rgba(0, 0, 0, 0.05),
                    0px 0px 80px rgba(0, 0, 0, 0.07);
                }
                img {
                  width: 25px;
                  height: 25px;
                }
              }
              .wallet-address {
                font-size: 18px;
              }
            }
            .canditate-right-side {
              display: flex;
              justify-content: space-between;
              width: 35rem;
              font-size: 36px;
              @include media("<=480px") {
                display: none;
              }
              .start-amount {
                position: relative;
              }
              .end-amount {
                position: relative;
                left: -2rem;
              }
            }
          }
        }
      }
    }

    // ! Right side
    .right-side {
      width: 30%;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: $font-superStar;
      gap: 0.5rem;
      &.logged {
        display: none;
      }
      @include media("<=1500px") {
        display: none;
      }
      .region-filters-box {
        text-align: center;
        clip-path: $pixelBorderRadius;
        background-color: #292929;
        display: flex;
        justify-content: center;
        flex-direction: column;
        justify-content: space-between;
        font-size: 24px;
        height: 8rem;
        width: 22.5rem;
        padding: 1rem;
        box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
          6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
          12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
          22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
          41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
          100px 100px 80px rgba(0, 0, 0, 0.07);
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

        span {
          color: #ffa556;
          transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        }
      }
      .region-filters-box-logged {
        text-align: center;
        clip-path: $pixelBorderRadius;
        background-color: #292929;
        display: flex;
        justify-content: center;
        flex-direction: column;
        justify-content: space-between;
        font-size: 24px;
        height: 8rem;
        width: 22.5rem;
        padding: 1rem;
        box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
          6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
          12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
          22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
          41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
          100px 100px 80px rgba(0, 0, 0, 0.07);
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        cursor: $cursor-Pointer;
        &:hover {
          background-color: #292929;
        }
        span {
          color: #ffa556;
          transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        }
      }
      .logintojoin-leaderboard {
        position: relative;
        clip-path: $pixelBorderRadius;
        cursor: $cursor-Pointer;
        border-radius: 3.5px;
        width: 100%;
        font-size: 20px;
        color: black;
        display: flex;
        justify-content: center;
        line-height: normal;
        padding: 0.5rem 1rem;
        background-color: #ffa556;
        cursor: $cursor-Pointer;
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        &:hover {
          background-color: #cd772b;
        }
        .login-button-leaderboard {
          position: absolute;
          width: 100%;
          top: -1rem;
          opacity: 0;
        }
      }
      .leaderboard-warning {
        font-family: $font-Pixellari;
        max-width: 22.5rem;
        // line-height: normal;
        font-size: 20px;
        color: #ffa556;
      }
    }
  }
}
