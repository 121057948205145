// Include media for media queries
@import "/node_modules/include-media/dist/include-media";

$font-superStar: "SuperStar", Verdana, Geneva, Tahoma, sans-serif;
$cursor-Pointer: url("../../assets/media/Cursors/cursorPointer.png"), pointer;
$pixelBorderRadius: polygon(
  0px 8px,
  2px 8px,
  2px 4px,
  4px 4px,
  4px 2px,
  6px 2px,
  8px 2px,
  8px 0px,
  calc(100% - 8px) 0px,
  calc(100% - 8px) 2px,
  calc(100% - 4px) 2px,
  calc(100% - 4px) 4px,
  calc(100% - 2px) 4px,
  calc(100% - 2px) 6px,
  calc(100% - 2px) 8px,
  100% 8px,
  100% calc(100% - 8px),
  calc(100% - 2px) calc(100% - 8px),
  calc(100% - 2px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 2px),
  calc(100% - 6px) calc(100% - 2px),
  calc(100% - 8px) calc(100% - 2px),
  calc(100% - 8px) 100%,
  8px 100%,
  8px calc(100% - 2px),
  4px calc(100% - 2px),
  4px calc(100% - 4px),
  2px calc(100% - 4px),
  2px calc(100% - 6px),
  2px calc(100% - 8px),
  0px calc(100% - 8px)
);

.order-history-wrapper {
  margin: 2rem 3rem 2rem 2rem;
  @include media("<=889px") {
    margin: 3rem 0 0 0;
  }
  .order-history {
    clip-path: $pixelBorderRadius;
    background-color: #363636;
    padding: 0rem 1rem 1.5rem 1rem;

    @include media("<=889px") {
      width: 100%;
      margin: 0;
      clip-path: none;
    }
    @include media("<=480px") {
      width: 100%;
      margin: 0;
      overflow: hidden;
    }
    .order-history-buttons {
      // background-color: #ff000050;
      display: flex;
      font-size: 24px;
      font-family: $font-superStar;
      color: white;
      gap: 0.5rem;
      @include media("<=480px") {
        font-size: 20px;
        justify-content: space-between;
      }
      .tab {
        cursor: $cursor-Pointer;
        padding: 0.5rem;
        color: #fff;
        transition: color 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        &.selected {
          color: #ffa556;
          border-bottom: 3px solid #ffa556;
        }
        &:hover {
          color: #f7b378;
        }
      }
    }

    .table_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 0;
      @include media("<=480px") {
        display: none;
      }
      .table_header_title {
        font-size: 24px;
        font-family: $font-superStar;
        color: #9a9a9a;
        @include media("<=889px") {
          font-size: 18px;
        }
      }
    }

    .table-content-wrapper {
      display: flex;
      flex-direction: column;
      min-height: 25rem;
      max-height: 35rem;
      overflow-y: auto;
      overflow-x: hidden;
      .no-orders-found {
        line-height: normal;
        font-size: 28px;
        font-family: $font-superStar;
        color: #c5c5c5;
        @include media("<=889px") {
          font-size: 24px;
        }
        @include media("<=480px") {
          position: relative;
          top: 1rem;
        }
      }
      .table_content {
        display: flex;
        font-size: 24px;
        justify-content: space-between;
        font-family: $font-superStar;
        color: #fff;
        align-items: center;
        position: relative;
        min-height: 4rem;
        border-bottom: #666565 solid 1px;
        @include media("<=889px") {
          font-size: 18px;
        }
        .order-type-wrapper {
          display: flex;
          position: absolute;
          width: 28.5%;
          justify-content: space-between;
        }
        .amount-price-wrapper {
          display: flex;
          width: 15%;
          position: absolute;
          left: 39%;
          justify-content: space-between;
        }

        .total-date-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          left: 62%;
          width: 15%;

          .table_content_info_date {
            font-size: 20px;
            @include media("<=889px") {
              font-size: 14px;
            }
          }
        }
        .statusaction-wrapper {
          right: 0%;
          position: absolute;
          display: flex;
          width: 15.5%;
          justify-content: space-between;
          padding-right: 1rem;
          @include media("<=889px") {
            padding: 0;
            width: 18%;
          }
          .table_content_info {
            display: flex;
            align-items: center;
          }
          .table_content_button {
            cursor: $cursor-Pointer;
            color: white;
            border: none;
            outline: none;
            background-color: #9a9a9a;
            width: 2rem;
            height: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            clip-path: $pixelBorderRadius;
            transition: background-color 0.3s
              cubic-bezier(0.075, 0.82, 0.165, 1);
            &:hover {
              background-color: #ffa556;
            }
            &.disabled {
              cursor: url("../../assets/media/Cursors/cursorDefault.png"), auto;
              background-color: #9a9a9a73;
              svg {
                path {
                  fill: rgba(221, 221, 221, 0.224);
                }
              }
            }
          }
        }
      }
    }
  }
}
