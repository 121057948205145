// Include media for media queries
@import "/node_modules/include-media/dist/include-media";

$font-superStar: "SuperStar", Verdana, Geneva, Tahoma, sans-serif;
$cursor-Pointer: url("../../assets/media/Cursors/cursorPointer.png"), pointer;
$pixelBorderRadius: polygon(
  0px 8px,
  2px 8px,
  2px 4px,
  4px 4px,
  4px 2px,
  6px 2px,
  8px 2px,
  8px 0px,
  calc(100% - 8px) 0px,
  calc(100% - 8px) 2px,
  calc(100% - 4px) 2px,
  calc(100% - 4px) 4px,
  calc(100% - 2px) 4px,
  calc(100% - 2px) 6px,
  calc(100% - 2px) 8px,
  100% 8px,
  100% calc(100% - 8px),
  calc(100% - 2px) calc(100% - 8px),
  calc(100% - 2px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 2px),
  calc(100% - 6px) calc(100% - 2px),
  calc(100% - 8px) calc(100% - 2px),
  calc(100% - 8px) 100%,
  8px 100%,
  8px calc(100% - 2px),
  4px calc(100% - 2px),
  4px calc(100% - 4px),
  2px calc(100% - 4px),
  2px calc(100% - 6px),
  2px calc(100% - 8px),
  0px calc(100% - 8px)
);

.home {
  width: 100vw;
  display: flex;
  flex-direction: column;

  .home-top-section {
    display: flex;
    flex-direction: column;
    overflow-x: hidden !important;
    @include media("<=480px") {
      margin-top: 1rem;
    }
    .home-top-inner {
      // background-color: red;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 1rem 2.95rem 1rem 2rem;
      @include media("<=889px") {
        gap: 1rem;
      }
      @include media("<=480px") {
        padding: 0;
      }
      .tradingview_chart {
        width: 69.5%;

        @include media(">=1920px") {
          width: 75%;
        }
        @include media(">=2200px") {
          width: 82.5%;
        }
        @include media("<=889px") {
          width: 100%;
        }
      }
      @include media("<=889px") {
        flex-direction: column;
      }
    }
    .home-top-inner-right {
      // clip-path: $pixelBorderRadius;
      display: flex;
      flex-direction: column;
      background-color: #0b0b0b;
      color: #fff;
      border-radius: 5px;
      @include media("<=480px") {
        clip-path: none;
      }
      .buysell-container {
        .buysellbox-buttons-wrapper {
          background-color: #252525;
          display: flex;
          font-family: $font-superStar;
          font-size: 24px;
          // gap: 1rem;
          padding: 0 1rem;
          align-items: center;
          .limitorder-button {
            cursor: $cursor-Pointer;
            padding: 0.5rem 1rem;
            background-color: #252525;
            color: #fff;
            transition: color 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
            &.clicked {
              background-color: #444444;
              color: #ffa556;
              border-bottom: 3px solid #ffa556;
            }
            &:hover {
              color: #f7b378;
            }
          }
          .ordermarket-button {
            cursor: $cursor-Pointer;
            padding: 0.5rem 1rem;
            background-color: #252525;
            color: #fff;
            transition: color 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
            &.clicked {
              background-color: #444444;
              color: #ffa556;
              border-bottom: 3px solid #ffa556;
            }
            &:hover {
              color: #ffa556;
            }
          }
        }
        .buy-sell {
          padding: 1.5rem;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          overflow: auto;

          .input-wrapper {
            display: flex;
            align-items: flex-start;
            padding: 0.5rem 0.75rem;
            flex-direction: column;
            background-color: #222222;
            font-size: 24px;
            font-family: $font-superStar;
            border-radius: 5px;
            .total-eth-value {
              color: #ffffff80;
              position: relative;
              left: 0.25rem;
              padding: 0 0.5rem;
            }
            span {
              position: relative;
              left: 0.3rem;
              line-height: normal;
            }
            label {
              position: relative;
              left: 0.3rem;
              line-height: normal;
            }
            input {
              width: 97.5%;
              padding: 0 0.5rem;
              border: none;
              background-color: #444444;
              color: #fff;
              font-size: 24px;
              border-radius: 5px;
              font-family: $font-superStar;
              &:focus {
                outline: none;
              }
            }
          }

          .buysell-buttons-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
          }

          .buysell-warning {
            border-radius: 5px;
            letter-spacing: 0.25px;
            font-size: 14px;
            color: #dedede;
            padding: 0.5rem;
            max-width: 21rem;
            line-height: normal;
          }
        }
      }
    }
  }

  .home-bottom-section {
    width: 100%;
  }
}

// Buttons

$outline-width: 6px;
$shadow-width: 4px;

$button-background: #ffa556;
$sec-button-background: #f8af70;
$shadow-color: #fd8c29;

$alt-button-background: #fa9b53;
$alt-sec-button-background: #e5813f;
$alt-shadow-color: #9e4a0b;

$reset-button-background: #1da1f2;
$reset-sec-button-background: #4cabe7;
$reset-shadow-color: #0a4f7a;

.buysell-button {
  border-radius: 5px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  font-family: $font-superStar;
  font-size: 22px;
  cursor: $cursor-Pointer;
  color: #f27921;
  padding: 0.4rem 1rem;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  background-color: #222222;
  &:hover {
    background-color: #151515;
  }
}
